import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week Starts Today`}</em></p>
    <p>{`DB Split Jerk 3×12`}</p>
    <p>{`DB Rows 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time:`}</p>
    <p>{`200M Run`}</p>
    <p>{`20 DB S2OH (35/25’s)(RX+ 50/35’s)`}</p>
    <p>{`10 Bar Muscle Ups`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      